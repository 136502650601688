@import "_config/_variables.scss";
@import "_config/_elements.scss";

* {
  box-sizing: border-box;
}

#embeddedContent {
  /* outline: dashed lime 2px; */
}

a {
  color: $tertiary-color;
  cursor: pointer;
  &:hover {
    color: $tertiary-color-htone;
    text-decoration: underline;
  }
  &:visited {
    color: $tertiary-color-ltone;
  }
}

a.button-action,
.button-action,
a.button-cta,
.button-cta,
a.button-normal,
.button-normal,
a.button-dangerous,
.button-dangerous {
  @extend %button;
  text-decoration: none;
}

a.button-cta,
.button-cta,
a.button-action,
.button-action {
  @extend %button-action;
  color: $main-color-contrast;
}

a.button-cta,
.button-cta {
  bottom: 0;
  font-size: large;
  height: 70px;
  left: 0;
  width: 100%;
}
a.button-normal,
.button-normal {
  @extend %button-normal;
}

a.button-dangerous,
.button-dangerous {
  @extend %button-dangerous;
}

.button-normal svg {
  fill: $secondary-color-contrast;
}

.button-normal:hover svg {
  fill: $main-color;
}

.w100 {
  width: 100%;
}

.main-color {
  &,
  & * {
    color: $main-color;
  }
}

.button-action.thinking:before {
  animation: thinking 3000ms infinite;
  background: linear-gradient(
    to right,
    transparent,
    $main-color-ltone,
    transparent
  );
  background-attachment: fixed;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.button-action.thinking {
  color: $dis-main-color-htone;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.button-cta svg,
.button-action svg {
  fill: $main-color-contrast;
}

svg {
  transition: ease fill 0.2s;
}

.button-dangerous.disabled,
.button-dangerous:disabled {
  background-color: desaturate($error-background, 30%);
  border-color: desaturate($error-color-ltone, 30%);
  color: desaturate($error-text-contrast, 30%);
}

.button-cta.disabled,
.button-action.disabled,
.button-action:disabled {
  background-color: $main-color-alpha40;
  border-color: $panel-background-alpha50;
  color: $dis-main-color-contrast;
}

.button-normal.disabled,
.button-normal:disabled {
  background-color: $dis-secondary-color;
  border-color: $dis-secondary-color-ltone;
  color: $dis-secondary-color-contrast;
}

.clr {
  clear: both;
}

.custom-menu {
  background: $panel-background;
  border-radius: 3px;
  border: 1px solid $panel-ltone;
  box-shadow: 0 0 8px 0 $panel-ltone;
  color: $panel-contrast;
  display: none;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  z-index: 1000;
}

.custom-menu li {
  padding: 5px 10px;
  cursor: pointer;
}

.custom-menu li:hover {
  background-color: $panel-htone;
}

.custom-menu a {
  color: inherit;
  text-decoration: none;
}

.icon {
  fill: $toolpanel-contrast;
  color: $toolpanel-contrast;
}

.notif_container .icon {
  fill: $secondary-color-ltone;
  color: $secondary-color-ltone;
}

.disabled,
.disabled * {
  color: $contrast-l;
  cursor: not-allowed;
  fill: $contrast-l;
  pointer-events: none;
}

.loading span,
.loading svg,
.disabled.loading svg use {
  animation-direction: alternate;
  animation: pulse 3000ms infinite;
  background: $contrast-m;
  border-radius: 32px;
  color: transparent;
  fill: $contrast-m;
}

.spinner-loader {
  pointer-events: none;
  position: absolute;
  top: 40px;
  transition: ease all 0.2s;
  z-index: 2000;
  width: 100%;
}

@keyframes k2000 {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0%;
  }
}
@keyframes pulse {
  0% {
    background: $contrast-m;
    fill: $contrast-m;
  }

  50% {
    background: $contrast-h;
    fill: $contrast-h;
  }

  100% {
    background: $contrast-m;
    fill: $contrast-m;
  }
}
@keyframes thinking {
  0% {
    margin-left: -50%;
  }
  50% {
    margin-left: 50%;
  }
  100% {
    margin-left: -50%;
  }
}

.margin10-left {
  margin-left: 10px;
}

.fright {
  float: right;
}

.fleft {
  float: left;
}

.main-content {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 40px;
}

.widget-close {
  color: $contrast-m;
  cursor: pointer;
  float: right;
  display: inline-block;
  /*inline-block is ignored due to the float.
	If 'float' has a value other than 'none',
	the box is floated and 'display' is treated as 'block' */
  font-size: large;
  font-weight: 700;
  margin: 5px;
  text-align: center;
  touch-action: manipulation;
  transition: ease all 150ms;
  width: 24px;
}

.widget-close:hover {
  color: $panel-contrast;
}

.widget-title {
  cursor: default;
  display: block;
  font-size: larger;
  font-weight: 700;
  margin: 5px 0;
}

input[type="tel"],
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="search"],
button,
textarea,
select {
  @extend %inputtemplate;
}

input[type="submit"]:disabled {
  cursor: default !important;
  pointer-events: none !important;
}

.load-animation {
  color: $shade-m;
  animation: grayglow 2s infinite;
}

.flash-border {
  animation: flashborder 0.4s infinite;
}

@keyframes grayglow {
  0% {
    color: $shade-h;
  }
  50% {
    color: $shade-m;
  }
  100% {
    color: $shade-h;
  }
}

@keyframes flashborder {
  0% {
    border-color: $tertiary-color-contrast;
  }
  50% {
    border-color: $tertiary-color;
  }
  100% {
    border-color: $tertiary-color-contrast;
  }
}

#dashboard {
  position: relative;
  top: 80px;
  height: 80%;
  text-align: center;

  .blank-slate-pf {
    background-color: $panel-background;
  }

  .row {
    padding: 10px;
  }
}

a.dash_button {
  background: $panel-background;
  border: 1px solid $panel-ltone;
  border-radius: 3px;
  color: $panel-contrast;
  display: inline-block;
  font-size: medium;
  padding: 10px;
  min-width: 20%;
  margin: 10px;
  text-decoration: none;
  transition: ease all 0.2s;
  transition: ease all 0.4s;
}

.dash_button .icon {
  transition: ease all 0.2s;
}
.dash_button:hover .icon {
  color: $main-color;
  fill: $main-color;
}
.dash_button:hover {
  background: $toolpanel-ltone;
  color: $toolpanel-contrast;
}

.dash_button .icon {
  display: block;
  height: 24px;
  margin: 5px auto;
  width: 24px;
  font-size: 19px;
}

.centered {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 500px;
  height: 300px;
  margin: -250px 0 0 -250px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: $toolpanel-ltone;
}

::-webkit-scrollbar {
  width: 10px;
  height: 11px;
  border-radius: 3px;
  background-color: $toolpanel-ltone;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #999;
}

.production_item_quantity {
  width: 50px;
}

.onoffswitch {
  background-color: $toolpanel-ltone;
  border: 1px solid $panel-htone;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  margin: 5px 0;
  padding: 0;
  overflow: hidden;
  width: 40px;
}

.onoffswitch .switch {
  display: block;
  height: 20px;
  transition: ease all 0.2s;
  width: 80px;
}

.onoffswitch .switch.on {
  margin-left: 0;
}

.onoffswitch .switch.off {
  margin-left: -20px;
}
.support-group-toggle.off .toggle-on {
  display: none;
}

.onoffswitch .switch .on-label,
.onoffswitch .switch .off-label {
  display: inline;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  width: 20px;
  float: left;
  height: 20px;
  /* Property is ignored due to the display. With 'display: inline',
	the width, height, margin-top, margin-bottom, and float properties have no effect. */
}

.onoffswitch .switch .on-label {
  background: $main-color;
  color: $main-color-contrast;
  box-shadow: 10px 0 0 0 $main-color;
}

.onoffswitch .switch .off-label {
  background: $toolpanel-ltone;
  color: $secondary-color-contrast;
}

.onoffswitch .switch .thumb {
  background-color: $toolpanel-htone;
  border-radius: 100%;
  box-shadow: inset -1px -1px 3px $toolpanel-ltone;
  display: inline;
  float: left;
  height: 20px;
  width: 20px;
  /* Property is ignored due to the display. With 'display: inline',
	the width, height, margin-top, margin-bottom, and float properties have no effect. */
}

.progress.indeterminate > .progress-bar {
  animation-direction: alternate;
  animation: k2000 3000ms infinite;
  position: relative;
}

.acenter {
  text-align: center;
}

.margin20 {
  margin: 20px;
}

.withCtaButton {
  padding-bottom: 80px;
}

.pointer {
  cursor: pointer;
}

.order-info {
  height: 24px;
  width: 24px;
  vertical-align: middle;
}

.tool-panel .icon {
  width: 16px;
  height: 16px;
}

.tool-panel svg.icon {
  vertical-align: initial;
}

.tool-panel .warning-icon .icon {
  margin-right: 9px;
}

.check_solidity {
  font-size: 13.5px;
  font-weight: bold;
}

.error:not(.icon) {
  @extend %notification;
  border-color: $error-color;
  background-color: $error-background;
  color: $error-text-contrast;
  svg.icon {
    fill: $error-text-contrast;
  }
  .close_notif {
    color: $error-text-contrast;
  }
  &.fright {
    width: auto;
  }
  a {
    color: $tertiary-color-contrast;
  }
}

tr.error {
  display: table-row;
}

.icon.error {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  fill: $error-color;
  color: $error-color;
}

.icon.warning {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  fill: $warning-color;
  color: $warning-color;
}

.flag,
.warning:not(.icon) {
  @extend %notification;
  border-color: $warning-color;
  background-color: $warning-background;
  &.fright {
    width: auto;
  }

  a {
    color: $tertiary-color-contrast;
  }
}

.hint {
  @extend %notification;
  border-color: $hint-color;
  background-color: $hint-background;
  color: $hint-color-htone;
  svg.icon {
    fill: $hint-color-htone;
  }
  .close_notif {
    color: $hint-color-htone;
  }
  a {
    color: $tertiary-color-contrast;
  }
}

.success {
  @extend %notification;
  border-color: $success-color;
  background-color: $success-color-htone;
}

.warning-icon,
.hint-icon,
.flag-icon,
.error-icon {
  margin: 0 5px -6px 0;
  vertical-align: middle;
}

.warning-icon .icon,
.flag-icon .icon,
.error-icon .icon {
  width: 19px;
  height: 19px;
  fill: $secondary-color-ltone;
  color: $secondary-color-ltone;
}

.hint-icon .icon,
.success-icon .icon {
  width: 22px;
  height: 22px;
}

.warning a,
.warning a:visited {
  color: $warning-color-ltone;
}

.warning a:focus,
.warning a:hover {
  color: $warning-color-mtone;
}

.tool-panel .warning a,
.tool-panel .warning a:visited {
  color: $warning-color-htone;
}

.tool-panel .warning a:focus,
.tool-panel .warning a:hover {
  color: $warning-color-htone-hover;
}

.error a,
.error a:visited {
  color: $error-color-ltone;
}

.error a:focus,
.error a:hover {
  color: $error-color-mtone;
}

.tool-panel .error a,
.tool-panel .error a:visited {
  color: $error-color-htone;
}

.tool-panel .error a:focus,
.tool-panel .error a:hover {
  color: $error-contrast;
}

button.hidden,
.button-normal.hidden,
.hidden {
  display: none;
}

.legal_footer {
  background-color: $panel-ltone;
  bottom: 0;
  font-size: small;
  left: 0;
  padding: 5px;
  position: fixed;
  right: 0;
  text-align: center;
}

.items_preprocess_frame {
  height: 100%;
  width: 100%;
}

.main-content.in-call .library-column {
  display: none !important;
}

.main-content.in-call .toolbar-main {
  display: none !important;
}

.main-content.in-call label[for="repairView"] {
  display: none !important;
}

.main-content.in-call .solidity-thin-spots {
  display: none !important;
}

.ui-widget-content {
  border-color: $panel-ltone;
  background: $panel-background;
  color: $panel-contrast;
}

.ui-widget.ui-widget-content {
  border-color: $panel-mtone;
  box-shadow: 0 0 15px 0 $panel-ltone;
}

.VS-interface.ui-autocomplete,
.ui-button,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active,
.ui-widget-header {
  background-color: $toolpanel-background;
  color: $toolpanel-contrast;
  border-color: $toolpanel-ltone;
}

.VS-interface.ui-autocomplete {
  overflow: hidden;
  z-index: 30;
}

.VS-interface.ui-autocomplete .ui-menu-item {
  padding: 3px;
}

.ui-button,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  &:hover {
    background-color: $toolpanel-htone;
    border-color: $toolpanel-mtone;
  }
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  border: 1px solid transparent;
  &:hover {
    background-color: $toolpanel-htone;
    border-color: $toolpanel-mtone;
    top: 2px;
  }
}

.ui-datepicker .ui-datepicker-next {
  &:hover {
    right: 2px;
  }
}

.ui-datepicker .ui-datepicker-prev {
  &:hover {
    left: 2px;
  }
}

.ui-widget-content .ui-state-highlight,
.ui-state-highlight {
  background-color: $toolpanel-htone;
  border-color: $toolpanel-contrast;
}

.stripped > :nth-child(odd) {
  background-color: #444;
}

.hide_in_fabpilot {
  display: none !important;
}
