@import "_config/_variables.scss";

.button-open-library,
.button-open-backlog {
  background-color: $glass-panel-background;
  border: 1px solid $panel-ltone;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  bottom: 50%;
  cursor: pointer;
  height: 45px;
  right: -30px;
  text-align: center;
  transition: ease all 0.2s;
  position: absolute;
  width: 30px;
}

.icon-open-library,
.icon-open-backlog {
  height: 30px;
  margin: 8px 0;
  transform: rotate(180deg);
  transition: ease all 150ms;
  width: 30px;
}

.button-open-library svg,
.button-open-backlog svg {
  fill: $panel-contrast;
}

.button-open-library:hover,
.button-open-backlog:hover {
  border-color: $panel-htone;
}

.button-open-library:hover svg,
.button-open-backlog:hover svg {
  fill: $panel-contrast;
}

.panel-upload {
  position: absolute;
  transition: ease all 150ms;
}

.dropzone-upload {
  animation-direction: alternate;
  animation: border-pulse 3000ms infinite;
  border-radius: 10px;
  border: dashed 2px;
  cursor: default;
  height: 90%;
  line-height: 130%;
  margin: 4%;
  position: relative;
  text-align: center;
  width: 90%;
}

.dropzone-upload span {
  display: block;
  position: absolute;
  top: 45%;
  width: 100%;
}

#mainLibrary {
  display: none;
}

.icon-upload,
.icon-project {
  fill: $panel-contrast;
  height: 24px;
  vertical-align: middle;
  width: 24px;
}

.button-library-tree {
  background: $glass-panel-mtone;
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  &:hover {
    background: $glass-panel-htone;
  }

  &.button-project {
    position: absolute;
    bottom: 0;
    height: 70px;
    line-height: 70px;
  }
  &:visited {
    color: $panel-contrast;
  }
}

.library-column {
  width: 300px;
  height: 100%;
  position: relative;
  z-index: 4;
  background-color: $glass-panel-background;
  color: $panel-contrast;

  &:not(.container-pf-nav-pf-vertical) {
    float: left;
    border-right: 1px solid $glass-panel-htone;
    margin-left: -300px;
    transition: ease all 150ms;
  }
}

.library-column.container-pf-nav-pf-vertical {
  position: relative;
  z-index: 4;
}

.no-transitions {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: all 0 none !important;
  transition: none !important;
}

.library-column.expanded {
  background-color: $glass-panel-background;
}

#library-container {
  height: calc(100% - 70px);
  overflow-y: auto;
  overflow-x: hidden;
}

.library-column:not(.expanded).open ::-webkit-scrollbar,
.library-column:not(.expanded).open ::-webkit-scrollbar-corner,
.library-column:not(.expanded).open ::-webkit-scrollbar-track {
  background-color: $toolpanel-ltone-alpha20;
}

.library-column.open:not(.container-pf-nav-pf-vertical) {
  margin-left: 0 !important;
}

.library-closed {
  width: 100% !important;
  left: 0 !important;
}

.library-column.open .icon-open-library {
  transform: rotate(0deg);
}

.page-projects .library-column {
  padding: 0;
}

.panel-upload {
  background: $glass-panel-background;
  left: -100%;
  top: 0;
  height: 100%;
  pointer-events: none;
  width: 100%;
}

.panel-upload.open {
  left: 0;
}

@keyframes border-pulse {
  0% {
    border-color: $secondary-color;
  }

  50% {
    border-color: $shade-l;
  }

  100% {
    border-color: $secondary-color;
  }
}

.upload-progress {
  position: absolute;
  bottom: 71px;
  left: 0;
  right: 0;
}

#library-search {
  background: $toolpanel-background;
  border: none;
  border-bottom: 1px solid $shade-l;
  border-radius: 0;
  padding: 10px;
  width: 100%;
}

.print_progress {
  background: $secondary-color-contrast;
  bottom: 0;
  height: 3px;
  position: absolute;
  width: 100%;
}

.print_progress .loader_bar {
  display: block !important;
  height: 100%;
  background: $main-color;
  width: 0;
}

/* JSTree customization */
.jstree-default-dark .jstree-anchor {
  width: 89%;
  width: calc(100% - 25px); // if calc available use it
  color: $panel-contrast;
  margin-bottom: 1px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
}

.jstree-default-dark .jstree-search {
  color: inherit;
  font-style: normal;
}
.jstree-default-dark .jstree-hovered {
  background: $panel-ltone;
}
.vakata-context {
  z-index: 5;
}
.vakata-context {
  background: $panel-background;
  border-radius: 3px;
  border: 1px solid $panel-ltone;
  box-shadow: 0 0 8px 0 $panel-shadow;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
}
.vakata-context li > a {
  padding: 5px 10px;
  cursor: pointer;
  line-height: 1;
  text-shadow: none;
  border-radius: 0;
  color: $panel-contrast;
  background-color: $panel-background;
}
.vakata-context li:hover {
  background-color: $panel-htone;
  color: $panel-contrast;
}
.vakata-context li > a:hover,
.vakata-context .vakata-context-hover > a {
  background-color: $panel-htone;
  color: $panel-contrast;
  box-shadow: none;
}
.vakata-context li > a > i,
.vakata-context li > a .vakata-contextmenu-sep {
  display: none;
}

#library-container.jstree-default-dark {
  background: $glass-panel-background;
}
#library-container.jstree-default-dark .jstree-clicked {
  background: $panel-htone;
}

#library-container.jstree-default-dark .jstree-closed .library-item-selected,
#library-container.jstree-default-dark .library-item.library-item-selected,
#library-container.jstree-default-dark .jstree-loaded-in-viewer {
  background: $panel-ltone;
  color: $tertiary-color;
}

#library-container.jstree-default-dark .library-item.library-item-selected svg,
#library-container.jstree-default-dark .jstree-loaded-in-viewer svg {
  fill: $panel-contrast;
}

.folder-open,
.jstree-clicked .folder-closed {
  display: none;
}

.folder-closed,
.jstree-clicked .folder-open {
  display: inline-block;
}

.jstree-closed > .jstree-anchor > .icon__folder > .folder-closed,
.jstree-closed > .jstree-anchor > .icon__project > .folder-closed,
.jstree-open > .jstree-anchor > .icon__folder > .folder-open,
.jstree-open > .jstree-anchor > .icon__project > .folder-open {
  display: inline-block;
}

.jstree-open > .jstree-anchor > .icon__folder > .folder-closed,
.jstree-open > .jstree-anchor > .icon__project > .folder-closed,
.jstree-closed > .jstree-anchor > .icon__folder > .folder-open,
.jstree-closed > .jstree-anchor > .icon__project > .folder-open {
  display: none;
}

.embedded_content.library-open {
  width: calc(100% - 300px);
  left: 300px;
}

.embedded_content {
  height: 100%;
  left: 0;
  overflow: visible; // Can be set back to auto if it's cause bug in some cases
  position: absolute;
  transition: ease all 150ms;
  width: calc(100%);
  z-index: 3;
  box-shadow: -300px 0 0 0 $toolpanel-background;
}

.embedded_content iframe {
  background-color: $viewer-htone;
  width: 100%;
  height: 100%;
}

.next_line_tab {
  display: block;
}

/* Animations */
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Library specific Visual Search style */
.search-bar {
  overflow: hidden;
}

.search-bar .VS-search-box {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid $toolpanel-ltone;
  background-color: $glass-panel-background;
  box-shadow: none;
  color: $toolpanel-contrast;
}
.search-bar .VS-search-box::after {
  content: "";
  clear: both;
  display: block;
}
.search-bar .VS-search-box input {
  background: none;
}
.VS-search .search_input.is_editing input {
  color: $toolpanel-contrast;
}

.VS-icon-search {
  display: none;
}
.search-bar .VS-placeholder {
  margin-left: 5px;
}

.VS-search .VS-search-inner {
  margin-left: 0;
}

#uploadMM,
#uploadIN {
  margin: 5px;
}

div:not(.popover-content, .panel-body) > #project-logs.logbox {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: 330px;
  margin-right: 140px;
  box-shadow: 3px 3px 3px 0 $panel-shadow;
}

.close-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 34px;
  padding: 10px;
  color: $contrast-m;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  transition: ease all 0.2s;
}

.close-panel:hover {
  color: $error-color;
  text-shadow: 0 0 2px $error-color-ltone;
}

#middle_container {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

#middle_container .logbox {
  pointer-events: all !important;
}

#middle_container .message.hide {
  display: none;
}

#middle_container .message {
  width: 80%;
  height: 50%;
  margin: auto;
  text-align: center;
}
#middle_container .message p {
  color: $panel-ltone;
}

#middle_container .fabpilot-logo {
  height: 5vw;
  width: 100%;
}

#middle_container .content {
  width: 50%;
  margin: 2% 25%;
}

@media screen and (max-width: 1110px) {
  #middle_container .content {
    width: 56%;
    margin: 3% 22%;
  }
}

@media screen and (min-width: 1300px) {
  #middle_container .content {
    width: 44%;
    margin: 3% 28%;
  }
}

#middle_container .content p {
  font-size: 1.6vw;
}

@media screen and (min-width: 900px) {
  #middle_container .content p {
    font-size: 15px;
  }
}

#middle_container .arrow_img {
  height: 300px;
  margin-right: 300px;
  margin-top: -50px;
  transform: rotate(120deg);
  width: 300px;
}

#middle_container #project-logs.logbox {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  margin: 0;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.6);
  z-index: 99999;
}

.legend {
  position: absolute;
  background-color: $glass-panel-background;
  border-radius: 6px;
  border: 1px solid;
  padding: 15px;
  border-color: $panel-htone;
  z-index: 3;
}

.legend:not(.cartouche) {
  top: 15px;
  left: -225px;
}

.legend.cartouche {
  bottom: 18px;
  max-width: 330px;
  height: 190px;
  overflow: hidden;
  padding: 0;
  pointer-events: all;
  right: 18px;
  transition: ease-in-out all 150ms;
  width: calc(100% - 36px);
}

.legend.feature-hovered-info {
  bottom: 18px;
  overflow: hidden;
  padding: 2px;
  pointer-events: all;
  height: 28px;
  left: 18px;
  color: $toolpanel-contrast;
}

.legend.transfo-button {
  bottom: 18px;
  overflow: hidden;
  padding: 0;
  pointer-events: all;
  width: 32px;
  height: 32px;
}

.legend.transfo-button.rotate {
  left: 90px;
  color: $toolpanel-contrast;
  cursor: pointer;
}

.legend.cartouche.mid {
  max-width: 364px;
}

.legend.cartouche.large {
  max-width: 410px;
}

.legend.cartouche.extra-large {
  max-width: 460px;
}

.legend.cartouche.hide {
  display: none;
}

.legend.cartouche.reduce {
  width: 144px;
  height: 34px;
  line-height: 32px;
  padding: 0px 8px;
  color: $toolpanel-contrast;
}

.legend.cartouche.reduce:hover {
  cursor: pointer;
}

.legend.cartouche .reduced {
  font-size: 13px;
  font-weight: bold;
  transition: ease all 50ms;
  color: $shade-l;
}

.legend.cartouche .reduced:hover {
  color: $tertiary-color-htone;
}

.legend.cartouche:not(.reduce) .reduced {
  visibility: hidden;
  width: 0;
  height: 0;
}

.legend.cartouche.reduce .design-information {
  visibility: hidden;
  width: 0;
  height: 0;
}

.legend.cartouche:not(.reduce):hover #reduce_button {
  visibility: visible;
}

.design-information {
  display: inline-block;
  height: 100%;
  width: 100%;
}

#reduce_button {
  z-index: 40000;
  opacity: 0.75;
  font-size: 13px;
  text-align: left;
  color: $shade-l;
  position: absolute;
  padding: 6px 10px;
  background-color: rgba(29, 29, 29, 0.5);
  border-radius: 0 5px 0 5px;
  top: 0;
  right: 0;
}

#reduce_button:hover {
  background-color: rgb(29, 29, 29);
  color: $tertiary-color-ltone;
  cursor: pointer;
  opacity: 1;
}

.legend.cartouche .design-info-content {
  position: relative;
  display: inline-block;
  width: 100%;
  color: $panel-contrast;
  padding: 10px;
}

.legend.cartouche .design-info-content.loading {
  opacity: 0.2;
}

.legend.cartouche .info-value {
  margin-bottom: 5px;
  overflow: hidden; // make sure it hides the content that overflows
  white-space: nowrap; // don't break the line
  text-overflow: ellipsis; // give the beautiful '...' effect
}

.design-information hr {
  border-color: $dis-tertiary-color-ltone;
  width: 100%;
  height: 1px;
  display: block;
}

.legend.cartouche .design-info-content #design_name {
  width: 100%;
  font-size: 15px;
  padding-right: 25px;
  overflow: hidden; // make sure it hides the content that overflows
  white-space: nowrap; // don't break the line
  text-overflow: ellipsis; // give the beautiful '...' effect
}

.legend.cartouche #cartouche-loading {
  height: 100%;
  width: 100%;
  background: url(../images/load-ellipsis.svg) center;
  background-repeat: no-repeat;
  background-size: 45%;
  position: absolute;
  top: 0;
}

.design-information .info-row {
  width: 50%;
  font-size: 13.3px;
  color: $panel-contrast;
  line-height: 150%;
  display: inline-block;
}

.info-row.d2 {
  width: 50%;
  float: left;
}

.info-row.d3 {
  width: 33%;
  float: left;
}

.design-information .info-title {
  color: #ccc;
  font-size: 13px;
  font-weight: 700;
}

.fabpilot-logo {
  fill: $panel-mtone;
  filter: drop-shadow(-1px -1px 0 $panel-ltone);
  stroke: $panel-ltone;
  stroke-width: 0.1%;
}

.left-bottom-arrow {
  transform-origin: 50% 50%;
  transform: rotate(178deg);
  fill: $toolpanel-ltone;
  width: 22%;
  margin-top: 3%;
  margin-left: 5%;
  float: left;
  height: 5vw;
}

@media screen and (max-width: 1300px) {
  .left-bottom-arrow {
    margin-left: 0;
  }
}

#library-dragbar {
  position: absolute;
  height: 100%;
  right: -5px;
  top: 0;
  width: 8px;
  z-index: 999999;
  cursor: e-resize;
  background-color: transparent;
}

#dragbar-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: ew-resize;
  // We can't use col-resize because in some browsers col-resize is inversed with row-resize...
  z-index: 99999;

  &.ns {
    cursor: ns-resize;
  }
}

#share_session {
  padding: 5px;
  left: 5px;
}

#share_session .row_amt {
  color: $panel-htone;
  font-size: 13.3px;
  margin-bottom: 5px;
}

#share_session .header-row {
  border-bottom-color: $dis-tertiary-color-ltone;
  color: $panel-contrast;
}

#share_session .row_amt.online {
  color: $panel-contrast;
}

#share_session .row_amt .project-widget-title {
  width: 100%;
}

.jstree-anchor.spinner-image:before {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../images/spin.svg");
  background-size: contain;
  background-position: 0 0 !important;
  animation: spin 1s infinite;
  animation-timing-function: linear;
  display: inline-block;
  margin: 3px 3px 0 3px;
}

// Manage job, prevent printer & material name from taking more than 1 line
.library-column:not(.expanded) span.info-title {
  display: none;
}
.library-column:not(.expanded) span.info-title + span {
  display: inline-block;
  width: 300px; // not-expanded library column size
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.library-column:not(.expanded) .batchLibraryContainer > .list-group-item {
  max-height: 118px;
  overflow: hidden;
}

#library-container {
  *::selection {
    background: transparent;
  }
  &.active-search {
    tr {
      display: none;
      &.searched {
        display: table-row;
      }
    }
    #toolbar-lib-results {
      position: relative;
      width: 100%;
      margin: 0;
      border-bottom: 1px solid $main-color-contrast;
      & > div {
        min-height: 40px;
        padding: 5px;
        & > span,
        & > ul,
        & > p {
          display: inline-block;
        }
        .label-info {
          padding: 3px 5px;
          display: inline-block;
          margin: 2px 0px;
        }
      }
    }
  }
  tr {
    background-color: transparent;
    &:hover {
      background-color: $toolpanel-ltone;
    }
  }
  td {
    border: none;
    background: transparent;
    position: relative;
    span:not(.fa-ellipsis-v) {
      display: inline-block;
      &.node-name {
        width: 50%;
        overflow: hidden;
        line-height: 1;
        height: 18px;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
    .dropdown-toggle {
      padding: 0;
      background: transparent;
      font-size: 16px;
      width: 16px;
    }
  }
  .line-library {
    position: relative;
    width: 1px;
    &:first-child:before {
      left: -5px;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 35px;
      left: -1px;
      top: -25px;
      background: $tertiary-color-ltone;
    }
  }
  .indent:not(.line-library) {
    position: relative;
    width: 1px;
    &:first-child:before {
      left: -5px;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 35px;
      left: -1px;
      top: -25px;
      background: $dis-secondary-color-ltone;
    }
  }
  .toolbar-pf-filter {
    margin: 5px;
  }
  #lib_filter_group_input {
    position: relative;
    width: 100%;
  }
}
