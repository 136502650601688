@import "_config/_variables.scss";

html {
  overflow-y: visible;
  position: static;
}
body {
  background: $panel-mtone;
  color: $panel-contrast;
  overflow-y: visible;
}

#signup label:not(.opt-in-desc) {
  margin: 3px;
}

#form_reset_password_confirmation,
#form_change_password,
#form_reset_password,
.floating-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  min-width: 300px;
  margin-left: -25%;
  transform: translateY(-50%);
  text-align: center;
}

.floating-form > div {
  margin-top: 15px;
}

.floating-form input[type="text"],
.floating-form input[type="email"],
.floating-form input[type="password"],
.floating-form select {
  border: 1px solid $secondary-color-ltone;
  border-radius: 3px;
}

.floating-form input[type="submit"] {
  cursor: pointer;
  text-transform: uppercase;
}

.floating-form .button-action {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13.3px;
}

.floating-form input[type="text"],
.floating-form input[type="email"],
.floating-form input[type="password"],
.floating-form select,
.floating-form input[type="submit"] {
  max-width: 300px;
}

.floating-form {
  a.button-action {
    display: block;
    max-width: 300px;
    display: inline-block;
    width: 100%;
  }

  .login_sso {
    margin-bottom: 20px;
  }
}

.top-bar {
  display: none;
}

.pass_hint {
  color: $toolpanel-contrast;
  padding: 15px;
}

.floating-form div.forgotton_pass {
  margin-top: 0px;
  font-size: small;
  width: 100%;
  padding-bottom: 15px;
  text-decoration: none;
  max-width: 300px;
  text-align: right;
  left: 0px;
  display: inline-block;

  a:hover {
    text-decoration: underline;
  }
}

#form_reset_password_confirmation input,
#form_change_password input,
#form_reset_password input,
.floating-form input,
.floating-form select {
  margin: 10px 0;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  line-height: 24px;
}

#form_reset_password_confirmation h1,
#form_change_password h1,
#form_reset_password h1,
.floating-form span {
  color: $toolpanel-contrast;
  display: block;
  font-size: x-large;
  margin-bottom: 30px;
}

.floating-form .logo-system {
  filter: drop-shadow(-1px -1px $panel-ltone);
  height: 65px;
  margin-left: 40px;
  vertical-align: middle;
  width: 200px;
}

.token-box {
  //display: flex;
  justify-content: space-between;
  vertical-align: middle;
  line-height: 8ch;
  font-weight: bold;
}

#token_separator {
  margin: 0 6px;
  width: 20px;
  display: inline-block;
  line-height: 20px;
  vertical-align: super;
}

input.token {
  width: 6ch;
  padding: 0.3ch 0.5ch;
  margin: 0 auto;
  font-size: 3rem;
  font-family: monospace;
  text-transform: uppercase;
  letter-spacing: 0.6ch;
  overflow: hidden;
}

.abs_block_left,
.abs_block_right {
  bottom: 30px;
  position: absolute;
  text-align: center;
  text-shadow: -1px -1px $panel-ltone;
  top: 0;
}

.abs_block_left {
  left: 0;
  right: 50%;
}

.abs_block_right {
  right: 0;
  left: 50%;
  overflow: hidden;
}

.abs_block_right img {
  position: absolute;
  bottom: 5%;
  top: 45%;
  left: 0;
  z-index: 0;
}

.abs_block_right header {
  position: relative;
  margin: 10% auto;
  max-width: 460px;
  z-index: 1;
}

.abs_block_right header h1 {
  font-size: xx-large;
  margin: 15px 30px;
}

.abs_block_right header h2 {
  font-size: x-large;
  margin: 15px 30px;
  margin-bottom: 30px;
}

.instance_query {
  text-align: center;
}

.instance_query input#instanceQuery {
  width: 50%;
  min-width: 150px;
  display: inline-block;
  text-align: right;
  font-size: x-large;
}

.instance_query span {
  text-align: left;
  display: inline-block;
}

.warning,
.error,
.hint {
  text-shadow: none;
}

#form_reset_password {
  .failure,
  .success,
  .progress-bar {
    max-width: 606px;
    margin: 10px auto;
    // At this point, email and submit buttons don't fit side by side
    @media screen and (max-width: 1208px) {
      max-width: 300px;
    }
  }
}

.red {
  color: $error-color;
}

@media screen and (max-width: 700px) {
  .abs_block_left {
    left: 0;
    right: 0;
  }

  .abs_block_right {
    display: none;
  }

  #form_reset_password_confirmation,
  #form_change_password,
  #form_reset_password,
  .floating-form {
    left: 50%;
    margin-left: -150px;
  }
}
