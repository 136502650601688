@import "_config/_variables.scss";

.feedback-btn {
  font-size: 14px;
  position: fixed;
  bottom: -3px;
  right: 60px;
  width: auto;
}

#feedback-module {
  color: $panel-ltone;
}

#feedback-module p {
  font-size: 13px;
}

#feedback-note-tmp {
  width: 444px;
  height: auto;
  min-height: 90px;
  outline: none;
  font-family: Arial, sans-serif;
  padding: 4px;
}

#feedback-note-tmp:focus,
#feedback-overview-note:focus {
  border: 1px solid #64b7cc;
}
#feedback-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

#feedback-welcome {
  top: 30%;
  left: 50%;
  margin-left: -270px;
  display: block;
  position: fixed;
}

.feedback-logo {
  width: 34px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: normal;
  line-height: 32px;
  padding-left: 40px;
  height: 32px;
}
.feedback-next-btn {
  width: 72px;
  height: 29px;
  line-height: 27px;
  float: right;
  font-size: 13px;
  padding: 0 8px;
}
.feedback-back-btn {
  width: 72px;
  height: 29px;
  line-height: 27px;
  float: right;
  font-size: 13px;
  padding: 0 8px;
  margin-right: 20px;
}

.feedback-submit-btn {
  height: 29px;
  line-height: 27px;
  float: right;
  font-size: 13px;
  padding: 0 8px;
}

.feedback-close-btn {
  width: 72px;
  height: 29px;
  line-height: 27px;
  float: right;
  font-size: 13px;
  padding: 0 8px;
}

.feedback-helper {
  background: rgba(0, 0, 0, 0);
  cursor: default;
}

.feedback-helper[data-type="highlight"] > .feedback-helper-inner {
  background: rgba(0, 68, 255, 0.1);
}
#feedback-close {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
}

.feedback-wizard-close {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  text-align: center;
  font-size: 28px;
  width: 30px;
  height: 30px;
  opacity: 0.65;
}

.feedback-wizard-close:hover {
  opacity: 1;
}
#feedback-welcome-error,
#feedback-overview-error {
  display: none;
  color: #f13e3e;
  float: right;
  margin-right: 30px;
  font-size: 13px;
  line-height: 29px;
}

#feedback-overview-error {
  margin-top: 20px;
}
#feedback-highlighter {
  display: none;
  bottom: 100px;
  right: 100px;
  position: fixed;
  width: 540px;
  height: 275px;
}

#feedback-overview {
  display: none;
  top: 10%;
  left: 50%;
  margin-left: -420px;
  position: fixed;
  width: 840px !important;
  height: auto;
}

#feedback-submit-error,
#feedback-submit-success {
  top: 30%;
  left: 50%;
  margin-left: -300px;
  display: block;
  position: fixed;
  width: 600px;
  height: auto;
}

.feedback-btn {
  padding: 10px;
  outline: 0;
  background-clip: padding-box;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  z-index: 40000;
}

.feedback-btn-gray {
  text-align: center;
  cursor: pointer;
  font-family: "Open sans";
  border: 1px solid #dcdcdc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #444;
  border-radius: 2px;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #f5f5f5, #f1f1f1);
}

.feedback-btn-gray:hover {
  color: #333;
  border: 1px solid #c6c6c6;
  background-color: #f8f8f8;
  background-image: linear-gradient(to bottom, #f8f8f8, #f1f1f1);
}

.feedback-btn-blue {
  text-align: center;
  cursor: pointer;
  font-family: "Open sans";
  border-radius: 2px;
  background-color: #357ae8;
  background-image: linear-gradient(to bottom, #4d90fe, #357ae8);
  border: 1px solid #2f5bb7;
  color: #fff;
}

#feedback-note-tmp,
#feedback-overview-note {
  resize: none;
}
#feedback-welcome,
#feedback-highlighter,
#feedback-overview,
#feedback-submit-success,
#feedback-submit-error {
  font-family: Arial, sans-serif;
  z-index: 40000;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.333);
  padding: 30px 42px;
  width: 540px;
  border: 1px solid rgba(0, 0, 0, 0.333);
  outline: 0;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  background-clip: padding-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-transform: translateZ();
  transform: translateZ();
}

.feedback-sethighlight,
.feedback-setblackout {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #f5f5f5, #f1f1f1);
  color: #444;
  border: 1px solid #dcdcdc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: default;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  margin-right: 16px;
  height: 30px;
  line-height: 28px;
  min-width: 90px;
  outline: 0;
  padding: 0 8px;
  display: inline-block;
  /*inline-block is ignored due to the float.
	If 'float' has a value other than 'none',
	the box is floated and 'display' is treated as 'block' */
  float: left;
}

.feedback-setblackout {
  margin-top: 10px;
  clear: both;
}

.feedback-sethighlight div {
  width: 16px;
  height: 16px;
  margin-top: 4px;
  float: left;
}

.feedback-setblackout div {
  width: 16px;
  height: 16px;
  font-size: 18px;
  float: left;
}

.feedback-sethighlight:hover,
.feedback-setblackout:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #f8f8f8;
  background-image: linear-gradient(to bottom, #f8f8f8, #f1f1f1);
  border: 1px solid #c6c6c6;
  color: #333;
}

.feedback-active {
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #eee;
  background-image: linear-gradient(to bottom, #eee, #e0e0e0);
  border: 1px solid #ccc;
  color: #333;
}

#feedback-highlighter label {
  float: left;
  margin: 0 0 0 10px;
  line-height: 30px;
  font-size: 13px;
  font-weight: normal;
}

#feedback-highlighter label.lower {
  margin-top: 10px;
}
.feedback-buttons {
  float: right;
  margin-top: 20px;
  clear: both;
}

#feedback-module h3 {
  font-weight: bold;
  font-size: 15px;
  margin: 8px 0;
}

.feedback-additional {
  margin-bottom: 20px !important;
}
#feedback-overview-description {
  float: left;
}
#feedback-overview-note {
  width: 314px;
  padding: 4px;
  height: 90px;
  outline: none;
  font-family: Arial, sans-serif;
}

#feedback-overview-screenshot {
  float: right;
}
.feedback-screenshot {
  max-width: 396px;
  padding: 1px;
  border: 1px solid #adadad;
}

#feedback-overview-description-text span {
  font-size: 14px;
  margin: 8px 0;
  color: #666;
  padding-left: 10px;
  margin-left: 26px;
}

#feedback-browser-info,
#feedback-page-info,
#feedback-page-structure,
#feedback-additional-none {
  margin-top: 16px;
  display: none;
}

.icon-comment {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
