.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.section.no-pad {
  padding: 0;
}
.section.no-pad-bot {
  padding-bottom: 0;
}
.section.no-pad-top {
  padding-top: 0;
}

.row_amt {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.row_amt:after {
  content: "";
  display: table;
  clear: both;
}
.row_amt .col {
  float: left;
  box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
}
.row_amt .col[class*="push-"],
.row_amt .col[class*="pull-"] {
  position: relative;
}
.row_amt .col.s1 {
  width: 8.33333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s2 {
  width: 16.66666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s4 {
  width: 33.33333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s5 {
  width: 41.66666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s7 {
  width: 58.33333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s8 {
  width: 66.66666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s10 {
  width: 83.33333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s11 {
  width: 91.66666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row_amt .col._offset-s1 {
  margin-left: 8.33333333%;
}
.row_amt .col._pull-s1 {
  right: 8.33333333%;
}
.row_amt .col._push-s1 {
  left: 8.33333333%;
}
.row_amt .col._offset-s2 {
  margin-left: 16.66666667%;
}
.row_amt .col._pull-s2 {
  right: 16.66666667%;
}
.row_amt .col._push-s2 {
  left: 16.66666667%;
}
.row_amt .col._offset-s3 {
  margin-left: 25%;
}
.row_amt .col._pull-s3 {
  right: 25%;
}
.row_amt .col._push-s3 {
  left: 25%;
}
.row_amt .col._offset-s4 {
  margin-left: 33.33333333%;
}
.row_amt .col._pull-s4 {
  right: 33.33333333%;
}
.row_amt .col._push-s4 {
  left: 33.33333333%;
}
.row_amt .col._offset-s5 {
  margin-left: 41.66666667%;
}
.row_amt .col._pull-s5 {
  right: 41.66666667%;
}
.row_amt .col._push-s5 {
  left: 41.66666667%;
}
.row_amt .col._offset-s6 {
  margin-left: 50%;
}
.row_amt .col._pull-s6 {
  right: 50%;
}
.row_amt .col._push-s6 {
  left: 50%;
}
.row_amt .col._offset-s7 {
  margin-left: 58.33333333%;
}
.row_amt .col._pull-s7 {
  right: 58.33333333%;
}
.row_amt .col._push-s7 {
  left: 58.33333333%;
}
.row_amt .col._offset-s8 {
  margin-left: 66.66666667%;
}
.row_amt .col._pull-s8 {
  right: 66.66666667%;
}
.row_amt .col._push-s8 {
  left: 66.66666667%;
}
.row_amt .col._offset-s9 {
  margin-left: 75%;
}
.row_amt .col._pull-s9 {
  right: 75%;
}
.row_amt .col._push-s9 {
  left: 75%;
}
.row_amt .col._offset-s10 {
  margin-left: 83.33333333%;
}
.row_amt .col._pull-s10 {
  right: 83.33333333%;
}
.row_amt .col._push-s10 {
  left: 83.33333333%;
}
.row_amt .col._offset-s11 {
  margin-left: 91.66666667%;
}
.row_amt .col._pull-s11 {
  right: 91.66666667%;
}
.row_amt .col._push-s11 {
  left: 91.66666667%;
}
.row_amt .col._offset-s12 {
  margin-left: 100%;
}
.row_amt .col._pull-s12 {
  right: 100%;
}
.row_amt .col._push-s12 {
  left: 100%;
}
@media only screen and (min-width: 601px) {
  .row_amt .col.m1 {
    width: 8.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m2 {
    width: 16.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m4 {
    width: 33.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m5 {
    width: 41.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m7 {
    width: 58.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m8 {
    width: 66.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m10 {
    width: 83.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m11 {
    width: 91.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col._offset-m1 {
    margin-left: 8.33333333%;
  }
  .row_amt .col._pull-m1 {
    right: 8.33333333%;
  }
  .row_amt .col._push-m1 {
    left: 8.33333333%;
  }
  .row_amt .col._offset-m2 {
    margin-left: 16.66666667%;
  }
  .row_amt .col._pull-m2 {
    right: 16.66666667%;
  }
  .row_amt .col._push-m2 {
    left: 16.66666667%;
  }
  .row_amt .col._offset-m3 {
    margin-left: 25%;
  }
  .row_amt .col._pull-m3 {
    right: 25%;
  }
  .row_amt .col._push-m3 {
    left: 25%;
  }
  .row_amt .col._offset-m4 {
    margin-left: 33.33333333%;
  }
  .row_amt .col._pull-m4 {
    right: 33.33333333%;
  }
  .row_amt .col._push-m4 {
    left: 33.33333333%;
  }
  .row_amt .col._offset-m5 {
    margin-left: 41.66666667%;
  }
  .row_amt .col._pull-m5 {
    right: 41.66666667%;
  }
  .row_amt .col._push-m5 {
    left: 41.66666667%;
  }
  .row_amt .col._offset-m6 {
    margin-left: 50%;
  }
  .row_amt .col._pull-m6 {
    right: 50%;
  }
  .row_amt .col._push-m6 {
    left: 50%;
  }
  .row_amt .col._offset-m7 {
    margin-left: 58.33333333%;
  }
  .row_amt .col._pull-m7 {
    right: 58.33333333%;
  }
  .row_amt .col._push-m7 {
    left: 58.33333333%;
  }
  .row_amt .col._offset-m8 {
    margin-left: 66.66666667%;
  }
  .row_amt .col._pull-m8 {
    right: 66.66666667%;
  }
  .row_amt .col._push-m8 {
    left: 66.66666667%;
  }
  .row_amt .col._offset-m9 {
    margin-left: 75%;
  }
  .row_amt .col._pull-m9 {
    right: 75%;
  }
  .row_amt .col._push-m9 {
    left: 75%;
  }
  .row_amt .col._offset-m10 {
    margin-left: 83.33333333%;
  }
  .row_amt .col._pull-m10 {
    right: 83.33333333%;
  }
  .row_amt .col._push-m10 {
    left: 83.33333333%;
  }
  .row_amt .col._offset-m11 {
    margin-left: 91.66666667%;
  }
  .row_amt .col._pull-m11 {
    right: 91.66666667%;
  }
  .row_amt .col._push-m11 {
    left: 91.66666667%;
  }
  .row_amt .col._offset-m12 {
    margin-left: 100%;
  }
  .row_amt .col._pull-m12 {
    right: 100%;
  }
  .row_amt .col._push-m12 {
    left: 100%;
  }
}
@media only screen and (min-width: 993px) {
  .row_amt .col.l1 {
    width: 8.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l2 {
    width: 16.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l4 {
    width: 33.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l5 {
    width: 41.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l7 {
    width: 58.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l8 {
    width: 66.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l10 {
    width: 83.33333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l11 {
    width: 91.66666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row_amt .col._offset-l1 {
    margin-left: 8.33333333%;
  }
  .row_amt .col._pull-l1 {
    right: 8.33333333%;
  }
  .row_amt .col._push-l1 {
    left: 8.33333333%;
  }
  .row_amt .col._offset-l2 {
    margin-left: 16.66666667%;
  }
  .row_amt .col._pull-l2 {
    right: 16.66666667%;
  }
  .row_amt .col._push-l2 {
    left: 16.66666667%;
  }
  .row_amt .col._offset-l3 {
    margin-left: 25%;
  }
  .row_amt .col._pull-l3 {
    right: 25%;
  }
  .row_amt .col._push-l3 {
    left: 25%;
  }
  .row_amt .col._offset-l4 {
    margin-left: 33.33333333%;
  }
  .row_amt .col._pull-l4 {
    right: 33.33333333%;
  }
  .row_amt .col._push-l4 {
    left: 33.33333333%;
  }
  .row_amt .col._offset-l5 {
    margin-left: 41.66666667%;
  }
  .row_amt .col._pull-l5 {
    right: 41.66666667%;
  }
  .row_amt .col._push-l5 {
    left: 41.66666667%;
  }
  .row_amt .col._offset-l6 {
    margin-left: 50%;
  }
  .row_amt .col._pull-l6 {
    right: 50%;
  }
  .row_amt .col._push-l6 {
    left: 50%;
  }
  .row_amt .col._offset-l7 {
    margin-left: 58.33333333%;
  }
  .row_amt .col._pull-l7 {
    right: 58.33333333%;
  }
  .row_amt .col._push-l7 {
    left: 58.33333333%;
  }
  .row_amt .col._offset-l8 {
    margin-left: 66.66666667%;
  }
  .row_amt .col._pull-l8 {
    right: 66.66666667%;
  }
  .row_amt .col._push-l8 {
    left: 66.66666667%;
  }
  .row_amt .col._offset-l9 {
    margin-left: 75%;
  }
  .row_amt .col._pull-l9 {
    right: 75%;
  }
  .row_amt .col._push-l9 {
    left: 75%;
  }
  .row_amt .col._offset-l10 {
    margin-left: 83.33333333%;
  }
  .row_amt .col._pull-l10 {
    right: 83.33333333%;
  }
  .row_amt .col._push-l10 {
    left: 83.33333333%;
  }
  .row_amt .col._offset-l11 {
    margin-left: 91.66666667%;
  }
  .row_amt .col._pull-l11 {
    right: 91.66666667%;
  }
  .row_amt .col._push-l11 {
    left: 91.66666667%;
  }
  .row_amt .col._offset-l12 {
    margin-left: 100%;
  }
  .row_amt .col._pull-l12 {
    right: 100%;
  }
  .row_amt .col._push-l12 {
    left: 100%;
  }
}
