@import "_config/_variables.scss";

// .navbar-pf-vertical{
//     position: fixed;
//     top: 0;
// 	width: 100%;
// 	z-index: 1;
// }

.top-bar {
  background: $panel-background;
  border-bottom: 1px solid $toolpanel-ltone;
  height: 35px;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 5;
}

html.layout-pf .top-bar {
  z-index: $zindex-navbar-fixed + 10;
}

.remaining-time {
  cursor: help;
}

#trialCountdownExpired,
#trialCountdown {
  background-color: $main-color;
  border-radius: 0 0 3px 3px;
  display: inline-block;
}

#trialCountdownExpired {
  background-color: $dis-tertiary-color-ltone;
  border-radius: 0 0 3px 3px;
}

@media (max-width: 925px) {
  #trialCountdown {
    display: none;
  }
}

#username_val {
  display: inline-block;
  font-size: smaller;
  height: 20px;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  vertical-align: middle;
  @media (max-width: 1090px) {
    display: none;
  }
}

.system-logo {
  height: 35px;
  width: 100px;
}

.logo-system {
  height: 35px;
  margin: 0;
  width: 100px;
}

.icon-menu {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 5px 0 5px 3px;
}

#topbar_right_side {
  float: right;
  margin: 0;
  margin-right: 5px;
}

.icon-user {
  height: 24px;
  margin-top: 5px;
  width: 24px;
}

/* Common styles for breadcrumb and submenus */
.top-bar ul {
  display: inline-block;
  vertical-align: top;
}

.top-bar li {
  display: inline-block;
  line-height: 34px;
  font-size: 14px;
  height: 35px;
}

.top-bar li a.disabled {
  color: $panel-ltone;
}

.top-bar li a {
  color: $panel-contrast;
  font-size: larger;
  font-weight: 700;
  text-decoration: none;
}

.top-bar a {
  display: inline-block;
  text-decoration: none;
}

/* Breadcrumb */
.system-logo,
.breadcrumb {
  float: left;
}

.top-bar .breadcrumb li.breadcrumb_entry:before,
.top-bar .breadcrumb li.breadcrumb_right_entry:after {
  content: " | ";
  color: $toolpanel-contrast;
  display: inline-block;
  margin: -9px 6px -9px 1px;
  vertical-align: text-top;
}

.top-bar .breadcrumb li.breadcrumb_right_entry:after {
  margin: -9px 5px -9px -3px;
  font-size: initial;
}

/* Submenus */
.top-bar .submenu {
  background: $panel-background;
  box-shadow: none;
  border-radius: 0 0 3px 3px;
  border-top: none;
  border: 1px solid $panel-ltone;
  display: none;
  overflow: hidden;
  height: 0;
  min-width: 150px;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 10000;
}

.top-bar #nav_breadcrumb .submenu {
  left: 120px;
}

.submenu:hover {
  animation: easeopen linear 0.3s forwards;
  display: block;
  height: auto;
}

@keyframes easeopen {
  0% {
    box-shadow: none;
    height: 0;
    padding: 0;
  }
  90% {
    box-shadow: none;
    height: 0;
    padding: 0;
  }
  100% {
    box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    height: auto;
  }
}

.top-bar .submenu li:hover {
  background: $panel-ltone;
  box-shadow: -10px 0 0 0 $panel-ltone, 10px 0 0 0 $panel-ltone;
}

.top-bar .submenu li:hover a,
.top-bar .submenu li:hover svg {
  fill: $main-color;
  color: $panel-contrast;
}

.top-bar .submenu li {
  display: block;
  padding: 0;
  transition: ease all 0.2s;
  min-height: 35px;
  height: auto;
}

.top-bar .submenu li a {
  display: block;
  width: 100%;
}

.top-bar .submenu li:before {
  display: none;
}

.submenu .icon {
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
  display: inline-block;
  vertical-align: middle;
}

.notif_container {
  background: transparent;
  height: auto;
  left: 50%;
  margin-left: -25%;
  position: absolute;
  //pointer-events: none;
  top: 55px;
  width: 50%;
  z-index: 1100;
}

//.notif_wrapper {
//	cursor: default;
//	text-align: center;
//}
//
//.notif_container .flag .close_notif {
//	display: none;
//}
//.notif_container .flag {
//	display: flex;
//	flex-direction: row;
//	justify-content: center;
//	padding-right: 10px;
//}
//
//.notif_container ul {
//	display: block;
//}
//.notif_container li {
//	display: list-item;
//	list-style: square;
//	line-height: 20px;
//	height: 20px;
//	margin-left: 20px;
//}
//
//.notif_container dl dt {
//	margin-top: .5rem;
//	font-size: 1rem;
//}
//.notif_container dl dt::after {
//	content: ':';
//}
//.notif_container dl dd {
//	margin-top: -.25rem;
//	margin-left: 25px;
//	display: list-item;
//	list-style: square;
//	font-size: 1rem;
//}
//
//.close_notif {
//	color: $contrast-l;
//	cursor: pointer;
//	display: inline-block;
//	font-weight: 700;
//	height: 24px;
//	margin-top: -13px;
//	position: absolute;
//	right: 10px;
//	top: 50%;
//	transition: ease all 0.2s;
//}
//
//.close_notif:hover {
//	color: $error-color;
//}

#trialCountdown,
#trialCountdownExpired {
  color: $dis-tertiary-color-contrast;
  font-weight: 700;
  font-size: 15.4px;
  line-height: 35px;
  margin: 0 auto;
  padding: 0 15px;
}

#trialCountdown.hidden,
#trialCountdownExpired.hidden {
  display: none;
}

#trialCountdown a,
#trialCountdownExpired a {
  color: $dis-tertiary-color-contrast;
  text-decoration: underline;
}

.icon.icon-circle {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 1px;
  right: 6px;
  fill: $tertiary-color-ltone;
  stroke: $tertiary-color;
  stroke-width: 10px;
}

#guided_tour_notification {
  display: none;
}

.breadcrumb .highlight,
.breadcrumb .highlight a {
  color: $tertiary-color !important; // Don't be overwrited by hover
}

.breadcrumb .highlight svg {
  fill: $tertiary-color !important; // Don't be overwrited by hover
}

.top-bar .submenu li.guided-tour {
  display: none;
}

#notification-pop-up-back {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 50;
}

#notification-pop-up,
.notification-pop-up {
  color: $shade-h;
  display: none;
  position: fixed;
  width: 540px;
  min-height: 120px;
  border-radius: 4px;
  z-index: 1050;
  background: $toolpanel-background;
  border: 1px solid $toolpanel-ltone;
  padding: 10px;
  outline: 0;
  background-clip: padding-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-shadow: 0 0 30px 5px $panel-shadow;
  -webkit-box-shadow: 0 0 30px 5px $panel-shadow;
  -o-box-shadow: 0 0 30px 5px $panel-shadow;
  box-shadow: 0 0 30px 5px $panel-shadow;
  overflow: auto;
  max-height: calc(100vh - 60px);
  .content {
    height: 100%;
  }
  &.active {
    display: block;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: auto;
    height: auto;
  }
  .iframe-content {
    height: calc(100% - 20px);
    width: calc(100% - 40px);
    left: auto;
    top: auto;
    position: relative;
  }

  .iframe-content {
    top: 20px;
    left: 20px;
    position: absolute;
  }

  .iframe-icon-container {
    display: none;
    text-align: center;
  }
}

#notification-pop-up .close-button,
.notification-pop-up .close-button {
  margin-right: 0;
  z-index: 1;
}

.custom-title-topbar {
  float: left;
  white-space: nowrap;
  overflow: hidden;
  max-width: 175px;
  text-overflow: ellipsis;
}

#tech-logs-link {
  position: fixed;
  top: 0;
  left: 48%;
  background: $tertiary-color;
  padding: 10px 20px;
  z-index: 10;
  color: $main-color-contrast;
}

li#custom-content-dropdown {
  & > li {
    padding: 10px 0;
  }
}
