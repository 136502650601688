$nav-pf-vertical-width: 200px;
$navbar-pf-height: 40px;
$zindex-navbar-fixed: 1030;

// Colors
// ========================================
$main-color: #49e;
$main-color-bg: #629dcf;
$main-color-bg-light: #b3d0e8;
$main-color-bg-lighter: #d5e6f5;
$main-color-txt: #0d314f;
$main-color-htone: #5af;
$main-color-ltone: #38d;
$main-color-vltone: #125c96;
$main-color-contrast: #fff;

$main-color-alpha40: rgba(68, 153, 238, 0.4);
$main-color-alpha60: rgba(68, 153, 238, 0.6);
$main-color-htone-alpha70: rgba(85, 170, 255, 0.7);
$main-active-background-menu: #393f44;

$dis-main-color: #8be;
$dis-main-color-htone: #9cf;
$dis-main-color-ltone: #7ad;
$dis-main-color-contrast: #eee;

$secondary-color: #555;
$secondary-color-htone-bis: #585858;
$secondary-color-htone: #666;
$secondary-color-ltone: #444;
$secondary-color-contrast: #eee;

$dis-secondary-color: #777;
$dis-secondary-color-htone: #888;
$dis-secondary-color-ltone: #666;
$dis-secondary-color-contrast: #aaa;

$tertiary-color: #fa5;
$tertiary-color-htone: #fb6;
$tertiary-color-ltone: #e94;
$tertiary-color-contrast: #222;

$dis-tertiary-color: #fa5;
$dis-tertiary-color-htone: #fb6;
$dis-tertiary-color-ltone: #fa5;
$dis-tertiary-color-contrast: #111;

$glass-panel-background: rgba(27, 27, 27, 0.66);
$glass-panel-htone: rgba(27, 27, 27, 0.2);
$glass-panel-mtone: rgba(27, 27, 27, 0.5);
$panel-background: #3c3d3f;
$panel-background-alpha50: rgba(60, 61, 63, 0.5);
$panel-background-alpha30: rgba(60, 61, 63, 0.3);
$panel-htone: #5c5d5f;
$panel-htone-alpha60: rgba(92, 93, 95, 0.6);
$panel-mtone: #4c4d4f;
$panel-ltone: #2c2d2f;
$panel-shadow: rgba(0, 0, 0, 0.6);
$panel-contrast: #eee;
$toolpanel-background: #4f4f4f;
$toolpanel-htone: #7c7c7c;
$toolpanel-htone-alpha60: rgba(124, 124, 124, 0.6);
$toolpanel-mtone: #4c4c4c;
$toolpanel-ltone: #3c3c3c;
$toolpanel-ltone-alpha20: rgba(60, 60, 60, 0.2);
$toolpanel-contrast: #eeeeee;

$viewer-htone: #4a4b4d;
$viewer-ltone: #9ca1a4;

$shade-h: #eee;
$shade-m: #ddd;
$shade-l: #ccc;
$contrast-h: #aaa;
$contrast-m: #999;
$contrast-l: #777;

$error-color: #ff9494;
$error-text-contrast: #fff;
$error-contrast: #faa;
$error-color-htone: #ffe9e9;
$error-color-mtone: #c71a1a;
$error-color-ltone: #b33;
$error-background: #d92e2e;
$warning-color: #fc3;
$warning-color-htone: #fdf7e6;
$warning-color-htone-hover: #fdf0c9;
$warning-color-mtone: #cca225;
$warning-color-ltone: #906f0c;
$warning-background: #da1;
$hint-color: $main-color;
$hint-color-htone: #ebffff;
$hint-background: #27c;
$success-color: #3c3;
$success-contrast: #8e8;
$success-color-htone: #eaffea;
$success-background: #5e5;

$customer-service: #94e; // Used in job.scss line 1173

/* ------ Landing page colors -------- */

$blue: #377fc2;
$light-blue: rgba(75, 140, 255, 0.4);
$bright-blue: #377fc2;
$dark-blue: #205688;
$darker-blue: #143a5d;
$light-grey: #afafaf;
$yellow: #daab25;
$dark-yellow: #9c7812;
$orange: #da7825;
$dark-orange: #904608;
$landing-main-background: #292929;
$dark-landing-main-background: #151515;
$landing-main-background-violet: #241d27;

// PF Colors
// ========================================
$alert-info-bg: $toolpanel-background;
$alert-info-border: $main-color;
$alert-success-border: $success-color;
$alert-success-bg: adjust-color(
  $success-color,
  $lightness: -20%,
  $saturation: -50%
);
$alert-warning-border: $warning-color;
$alert-warning-bg: adjust-color(
  $warning-color,
  $lightness: -40%,
  $saturation: -50%
);
$alert-danger-border: $error-color;
$alert-danger-bg: adjust-color(
  $error-color,
  $lightness: -40%,
  $saturation: -50%
);

// Mixins
// ========================================
@mixin main-bg {
  background: $viewer-ltone;
  background-image: linear-gradient(to top, transparent 0%, $viewer-htone 100%);
  background-attachment: fixed;
}

@mixin default-pseudo-elmt {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
